import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import OtherCaseStudy from "../../components/other-case"

import gogleads from "../../assets/img/gogleads.png"
import gsearch from "../../assets/img/google-search-logo.png"
import dads from "../../assets/img/discovery.png"
import youtube from "../../assets/img/youtube-logo-yatay.png"
import villamobil from "../../assets/img/villa-mobil-mockup.png"

import balance from "../../assets/img/balance-time-money.png"
import achievement from "../../assets/img/achievement-female.png"

import aysima from "../../assets/img/teams/35.jpg"
import serife from "../../assets/img/teams/20.jpg"
import bengu from "../../assets/img/teams/17.jpg"
import gizem from "../../assets/img/teams/24.jpg"






const ReferancesDetail = () => (
    <Layout>
        <SEO title="Villa Yapı Başarı Hikayesi" />
        <div className="referances-detail py60 container" style={{ maxWidth: 950}}>
            <div className="col-md-12 detail-left" >
                <b>BAŞARI HİKAYESİ</b>
                <h1>Villa Yapı</h1>
                <p>1999 yılında prefabrik konut, prefabrik ofis, şantiye binaları, prefabrik sosyal tesisler, hastaneler,
okullar ve hafif çelik yapı üretimine başlayan Villa Yapı, yurt içinde ve yurt dışında sayısız müşterisi
ile büyümeye devam ederken sektöre de yön veren bir üreticidir.  </p>
            </div>
            <div className="col-md-6 detail-left">
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                        <div className="box">
                            <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="col-md-6 project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>SEO Uzmanı:</li>
                            <li>Adwords Uzmanı:</li>
                            <li>İçerik Yazarı:</li>
                        </ul>
                        <ul>
                            <li><img src={gizem} alt="Gizem Altun Bostancı" />Gizem A. Bostancı</li>
                            <li><img src={bengu}  alt="Bengü Dinçer" />Bengü Dinçer</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={aysima}  alt="Aysima Bakırcılar" />Aysima Bakırcılar</li>
                        </ul>
                    </div>

            </div>
        </div>
        <div className="container">

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img  src={villamobil} alt="villa yapı mobil mockup" />
                </div>
                <div className="col-md-6">
                    <p className="small" style={{ maxWidth: 450, color:"#ea4f6a", textAlign:"left" }} >Başarı Hikayesi</p>
                    <h3 style={{ maxWidth: 450, fontSize: 25, textAlign:"left" }}><b>Villa Yapı X Flatart</b></h3>
                    <p style={{ maxWidth: 450, textAlign:"left" }} >Flatart olarak Villa Yapı’nın büyük hedeflerle çıkmış olduğu bu yolda <b>SEO & SEM </b>alanındaki çözüm
ortağı olarak öncelikle marka beklentilerine ve hedef kitleye uygun bir stratejik çerçeve belirledik. </p>
                    <p style={{ maxWidth: 450, textAlign:"left"}}>Marka bilinirliğinin arttırılması, kaliteli organik trafik elde edilmesi ve bunun doğal sonucu olarak
lead generation odağında çalışmalarımızı sürdürdük. </p>
                    
                </div>
            </div>
            
                <div className="text-center">
                    <p className="small" style={{color:"#ea4f6a" }} >Başarı Hikayesi</p>
                    <h2 style={{ margin: '10px auto 20px', maxWidth: 900, fontSize:27 }}>1 yılda %43,10 oranında organik trafik artışı yakalandı. </h2>
                    <p style={{ fontSize: 18, margin: '20px auto 0', maxWidth: 850 }}>SEO odaklı içerik pazarlama stratejisi, on-page ve off-page SEO isterlerinin takibi ve aksiyona
    geçirilmesi sonucunda organik trafik, Ocak 2021 - Aralık 2021 döneminde bir önceki döneme kıyasla %43,10 oranında arttırıldı. </p>

                </div>


            <div className="text-center">
               
                <h2 style={{ margin: '50px auto 10px', maxWidth: 850, fontSize: 30}}><b>Prefabrikte Keşfin Gücü! </b> </h2>
                <p style={{ fontSize: 25, margin: '0 auto 30px'}}>Reklam Kanalları</p>
                <div className="col-md-4">
                    <img style={{ maxHeight: 120}} src={gogleads} alt="Google Ads" />
                    <p style={{ fontSize: 25, margin: '10px auto 30px'}}>Google Ads</p>
                </div>
                <div className="col-md-4">
                    <img style={{ maxHeight: 120}} src={gsearch} alt="Google search" />
                    <p style={{ fontSize: 25, margin: '10px auto 30px'}}>Search</p>
                </div>
                <div className="col-md-4">
                    <img style={{ maxHeight: 120}} src={dads} alt="Discovery Ads" />
                    <p style={{ fontSize: 25, margin: '10px auto 30px'}}>Discovery Ads</p>
                </div>
            </div>

            <div className="text-center villa-kutu" style={{ fontSize: 18, margin: '50px auto 150px', maxWidth: 850 }}>
            <p style={{ fontSize: 18, margin: '40px auto' }}>Prefabrik sektörünün organik olarak en fazla etkileşim aldığı mecranın YouTube olduğunu tespit
ederek reklam çalışmalarımızı buraya mecraya odakladık. </p>

                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <img style={{ maxHeight: 50}} src={youtube} alt="youtube logo" />
                </div>
                <div className="col-md-4"></div>  
            </div>
            
            
          
            <div className="referances-detail py60" >
            <div className="container manage-google" style={{ padding: '40px 60px' }}>
                    
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={balance} />
                            <div>
                                <h3>İleri Seviyede Uzmanlarla Çalışma</h3>
                                <p>Sosyal medyanızı stajyer veya az deneyimli kişiler değil, alanında uzman bir ekip yönetir.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={achievement} />
                            <div>
                                <h3>Değer Oluşturma</h3>
                                <p>Yatırım geri dönüşünüzü artırmak için yüksek etkili çalışmaya odaklanır ve bu hedefe yönelik belirlediğimiz KPI’ları sürekli olarak ölçümleriz.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

       




        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
